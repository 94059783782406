import React, { useEffect, useState } from 'react';
import Header from '../baseComponents/Header';
import Footer from '../baseComponents/Footer';
import { Images } from '../baseComponents/Images';
import { Card, Row, Col, Image, Button } from "antd";
import { Container} from "react-bootstrap";
import { ArrowRightOutlined, CalendarOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import BackButton from '../baseComponents/BackButton';
// import bannerImage from '../baseComponents/BannerImage/2.png';

const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => { setTimeout(() => { setLoading(false); }, 2000); }, []);

  const [startIndex, setStartIndex] = useState(0); // State to track the start index of visible columns
  const columnsData = [
    (  <Col lg={12} md={12} className='mb-4'>
    <a class="sigma_service style-1 primary-bg" style={{ width: "35rem", height: "32rem" }}>
      <div className="sigma_service-body">
        <h2 className="text-white">ABOUT US</h2>
        <h4 className="text-white">Guide to Hindu Funeral</h4>
        <p className="text-white-para">Triangle Area Hindu Temples have joined hands together to
          serve Asian Indian community as well as the Non-Indian community. </p>
        <p className="text-white-para" style={{ marginTop: "10px" }}>Triangle Area Hindu Temples (TAHT) have organized
          several activities in the past such as, Blood Donation Drives, Kite
          Flying festivals, Health Fairs and Kids Summit. We are proud to continue
          our services to the local community which was well received and accepted
          by one and all.</p>
          <p className='text-white-para'>• To provide an independent platform to bring together triangle area community support under one umbrella for community and humanitarian services, religious discussion, community events, fundraising and spiritual discourses, to foster Hindu Culture and Values.</p>
          <p className='text-white-para'>• To solidify individual and independent resources for the community service activities, thus bringing the entire Indian community together under one roof.</p>
        <span href="" className="btn-link text-white">Learn More  <ArrowRightOutlined /></span>
      </div>

    </a>
  </Col>),
  ( <Col lg={12} md={12} className='mb-4'>
    <a class="sigma_service style-1 secondary-bg" style={{ width: "35rem", height: "37rem" }}>
      <div className="sigma_service-body">
        <h2 className="text-white">EXECUTIVE COMMITTEE</h2>
        <h4 className="text-white">Founder Members:</h4>
        <p className="text-white-para">
          <p>Mr. Prakash Devalapalli, Chairman</p>
          <p>Mr. Prashant Sonker,  Vice-Chairman</p>
          <p>Dr. Prasad Makam, Vice-Chairman </p>
          <p>Mr. Viral Patell</p>
          <p>Mr. Prasad Vanguri</p>
        </p>
      </div>
      <div className="sigma_service-body mt-2">
        <h4 className="text-white">Secretary:</h4>
        <p className="text-white-para">Ms. Deepika Malle</p>
        <p className="text-white-para">JT.Secretary: Mr.Chintan Kanani</p>
      </div>

      <div className="sigma_service-body mt-2">
        <h4 className="text-white">Treasurer:</h4>
        <p className="text-white-para">Ms. Anu Virkar</p>
        <p className="text-white-para">JT. Treasurer: Mr. Deep Patel</p>
      </div>

      <div className="sigma_service-body mt-2">
        <h4 className="text-white">Founders:</h4>
        <p className="text-white-para">
          <p>Dr. Nailesh Dave</p>
          <p>Dr. Jaylan Parikh</p>
          <p>Mr. Prasad Vanguri</p>
          <p>Dr. Bhaskar Venepalli</p>
        </p>
      </div>
      <span href="excommittee@tahts.org" className="text-white btn-link">excommittee@tahts.org <ArrowRightOutlined /></span>
    </a>
  </Col>),

  ( <Col lg={12} md={12} className='mb-4'>
    <a class="sigma_service style-1 primary-bg" style={{ width: "35rem", height: "32rem" }}>
    <div className="sigma_service-body">
        <h2 className="text-white">Funeral help</h2>
        <h4 className="text-white">Founder Members:</h4>
        <p className="text-white-para">
          <p>Mr. Kiran Krishna</p>
          <p>Mr. Madhu gangwal</p>
          <p>Mr. Prakash Patel</p>
          <p>Mrs. Promila </p>
          <p>Mrs. Domadia</p>

          <p>Mr. Ramesh Vora</p>
          <p>Mrs. Shvangi Joshi</p>
          <p>Mr. Suresh Kakarla</p>
          <p>Mr. Praveen  </p>
          <p>Mr. Tatineni</p>
        </p>
      </div>
        <span href="funeralhelp@tahts.org" className="btn-link text-white">funeralhelp@tahts.org <ArrowRightOutlined /></span>
    </a>
  </Col>),

 ( <Col lg={12} md={12} className='mb-4'>
    <a class="sigma_service style-1 secondary-bg" style={{ width: "35rem", height: "37rem" }}>
      <div className="sigma_service-body">
        <h2 className="text-white">Healthfair</h2>
        <h4 className="text-white">Founder Members:</h4>
        <p className="text-white-para">
          <p>Dr. Jaylan Parikh</p>
          <p>Mr. Jayman Gandhi</p>
          <p>Mr. Prashant Sonkar</p>
          <p>Mrs. Deepika Maale</p>
          <p>Mrs. Anu Virkar</p>
          <p>Dr. Girish Amin</p>
          <p>Mr. Shantan</p>
          <p>Mrs. Rekha Parikh</p>
          <p>Mr. Viral Patel</p>
          <p>Mrs. Anu Virkar</p>
          <p>Mr. Girish Amin</p>
          <p>Mr. Chintan Kanani</p>
          <p>Mr. Shantan</p>
          <p>Mrs. Rekha Parikh</p>
          <p>Mr. Viral Patel</p>
          <p>Mr. Vandana </p>
          <p>Mr. Devalapalli</p>
          <p>Dr. Makam</p>
          <p>Mr. Harshal Joshi</p>
          <p>Mr. R K Vanguri</p>
        </p>
      </div>
      <span href="healthfair@tahts.org" className="text-white btn-link">healthfair@tahts.org  <ArrowRightOutlined /></span>
    </a>
  </Col>),

(  <Col lg={12} md={12} className='mb-4'>
<a class="sigma_service style-1 primary-bg" style={{ width: "35rem", height: "32rem" }}>
<div className="sigma_service-body">
    <h2 className="text-white"> Outreach</h2>
    <h4 className="text-white">Founder Members:</h4>
    <p className="text-white-para">
      <p>Mr. Gunvant Bhakta</p>
      <p>Mr. Jothi V.Kumar</p>
      <p>Mrs. Anu Virkar</p>
      <p>Mrs. Usha Gulati</p>
    </p>
  </div>
    <span href="outreach@tahts.org" className="btn-link text-white">outreach@tahts.org <ArrowRightOutlined /></span>
</a>
  </Col>),

  ( <Col lg={12} md={12} className='mb-4'>
<a class="sigma_service style-1 secondary-bg" style={{ width: "35rem", height: "37rem" }}>
  <div className="sigma_service-body">
    <h2 className="text-white">Hospital/Medicare</h2>
    <h4 className="text-white">Founder Members:</h4>
    <p className="text-white-para">
      <p>Mrs. Anu Virkar</p>
      <p>Mr. Bharat Parikh</p>
      <p>Mr. praveen Shukla</p>
      <p>Mr. Yogesh Gandhi</p>
      <p>Mr. Baba Malleshappa</p>
      <p>Mrs. Meera Phaltankar</p>
      <p>Mr. Prashant Diwan</p>
      <p>Mr. Prashant Sonkar</p>
      <p>Mr. Sukesh Pai</p>
      <p>Mrs. Vanashree Selukar</p>
      <p>Mrs. Priyadarshan Phaltankar</p>
      <p>Mr. Vibha Goel</p>
      <p>Mr. Vijay Gupta</p>
      <p>Mr. Shivangi Joshi</p>
      <p>Mr. madhu mendiratta</p>
      <p>Mr. Prakash Patel</p>
      <p>Mr. Lalitha Venkatesh</p>
      <p>Mr. Nimiskha Patel</p>
    </p>
  </div>
  <span href="medicareadvise@tahts.org" className="text-white btn-link">medicareadvise@tahts.org  <ArrowRightOutlined /></span>
</a>
  </Col>),
    (  <Col lg={12} md={12} className='mb-4'>
    <a class="sigma_service style-1 primary-bg" style={{ width: "35rem", height: "32rem" }}>
    <div className="sigma_service-body">
        <h2 className="text-white">Disaster Relief</h2>
        <h4 className="text-white">Founder Members:</h4>
        <p className="text-white-para">
          <p>Mrs. Poornima Halnur </p>
          <p>Mr. Jashi Abhirajan </p>
          <p>Mr. Prasad Vanguri</p>
          <p>Mr. Pavan Yerramsetty </p>
          <p>Mr. Sujal Patel </p>
        </p>
      </div>
        <span href="disasterrelief@tahts.org" className="btn-link text-white">disasterrelief@tahts.org <ArrowRightOutlined /></span>
    </a>
      </Col>),

  ( <Col lg={12} md={12} className='mb-4'>
<a  class="sigma_service style-1 secondary-bg" style={{ width: "35rem", height: "38rem" }}>
  <div className="sigma_service-body">
    <h2 className="text-white">Senior Help</h2>
    <h4 className="text-white">Founder Members:</h4>
    <p className="text-white-para">
      <p>Mrs. Anu Virkar</p>
      <p>Mrs. Banu Krishnamurti </p>
      <p>Mr. Bharat Parikh</p>
      <p>Mr. Harsukh Gevaria</p>
      <p>Mr. Jothi V.Kumar</p>
      <p>Mrs. Karishma Shah</p>
      <p>Mr. K B Chandran & Vanaja </p>
      <p>Mr. Kiran Krishna </p>
      <p>Mrs. Madhu Gangwal</p>
      <p>Mr. Baba Malleshappa</p>
      <p>Mrs. Madhu mendiratta</p>
      <p>Mrs. Shilpa and Nitin Kundeshwar </p>
      <p>Mr. Prashant Diwan</p>
      <p>Mr. Prashant Sonker </p>
      <p>Mr. Prasad vanguri</p>
      <p>Mr. Ramesh Vora </p>
      <p>Mr. Ravindra Singh </p>
      <p>Mrs. Renu Jain</p>
      <p>Mrs. Shivagi Joshi</p>
      <p>Mrs. Sunita Gogate</p>
      <p>Mrs. Vanashree Selukar</p>
      <p>Mrs. Usha Gulati</p>
    </p>
  </div>
  <span href="info@tahts.org" className="text-white btn-link">info@tahts.org  <ArrowRightOutlined /></span>
</a>
  </Col>),



  ];
  const columnsPerPage = 2; 

  const showPrevColumns = () => {
    const newStartIndex = Math.max(startIndex - columnsPerPage, 0);
    setStartIndex(newStartIndex);
  };

  const showNextColumns = () => {
    const newStartIndex = Math.min(startIndex + columnsPerPage, columnsData.length - columnsPerPage);
    setStartIndex(newStartIndex);
  };

  return (
    <>
      <Header />

      {/* banner start */}
      <div className="banner dark-overlay dark-overlay-2" style={{ backgroundImage: 'url(images/banner/1.jpg)' }}>
        <div className="container">
          <div className="banner-inner">
            <h1>Triangle Area Hindu Temples</h1>
          </div>
        </div>
      </div>
      {/* banner ends */}

      {/* images starts */}
      <Container style={{ marginBottom: "20px", marginTop: "20px" }}>
        <Row>
          <Col lg={7} style={{ marginRight: "2rem" }}>
            <Card>
              <Image.PreviewGroup
                preview={{
                  onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                }}
              >
                <Image src="images/banner/letter/Health-Fair-2023.png" />
              </Image.PreviewGroup>
            </Card>
          </Col>
          <Col lg={7} style={{ marginRight: "2rem" }}>
            <Card>
              <Image.PreviewGroup
                preview={{
                  onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                }}
              >
                <Image src="images/banner/letter/CommunitySupport.png" />
              </Image.PreviewGroup>
            </Card>
          </Col>
          <Col lg={7}>
            <Card>
              <div className="sidebar-widget widget-recent-posts">
                <article className="sigma_recent-post">
                  {/* <div className="sigma_recent-post-body"> */}
                  <div className="widget-title" onClick={() => navigate(`/healthifair2023`)}
                  >Health Fair 2023 Information & Registration</div>
                  {/* </div> */}
                </article>
                <article className="sigma_recent-post">
                  <div className="sigma_recent-post-body">
                    <div>
                      <a href="https://docs.google.com/forms/d/12He5TTEJIgOh18PxMqQkRORdXBJXsAXc3EaFku_9JgI/edit" className="widget-title">Volunteer Registration</a>
                    </div>
                  </div>
                </article>
                <article className="sigma_recent-post">
                  <div className="sigma_recent-post-body">
                    <div>
                      <a href="https://docs.google.com/forms/d/1rdDERD5E3JYyYDRh4_jnrBF_VQyu4P-gIgmEqgR6WBQ/edit" className="widget-title">Physician Registration</a>
                    </div>
                  </div>
                </article>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* images ends */}

      {/*IMPORTANT INFORMATION starts */}
      <section className='information-background' >
        <div className='section section-lg section-shaped pb-80'style={{backgroundImage:'url(images/banner/2.png)'}} >
          <Container style={{ marginBottom: "20px", marginTop: "20px" }}>
            <div className='section-title '>
              <div className='subtitle mt-5'> ॐ Health Fair 2023 Information & Registration</div>
              <h1 className='title-cards'>IMPORTANT INFORMATION</h1>
            </div>
            <Row>
              <Col lg={5} md={7} className='mb-3'>
                <a  class="sigma_service style-1 primary-bg">

                  <div class="sigma_service-body">
                    <h2 class="text-white">Health fair</h2>
                    <h4 class="text-white">Health fair FAQ</h4>
                    <p class="text-white-para">Advice and Help in choosing and applying the right program. </p>
                    <h4 class="text-white">Contact:</h4>
                    <span href="mailto:healthfair@tahts.org" class="btn-link text-white">healthfair@tahts.org</span>
                  </div>
                  <span class="btn-link text-white">Guide to Health fair <ArrowRightOutlined />
                  </span>
                </a>
              </Col>

              <Col lg={5} md={7} className='mb-3'>
                <a class="sigma_service style-1 secondary-bg">

                <div class="sigma_service-body">
                    <h2 class="text-white">Funeral</h2>
                    <h4 class="text-white">Guide to Hindu Funeral</h4>
                    <p class="text-white-para">Full help with arrangement for funeral services, puja and later help.</p>
                    <h4 class="text-white">Contact:</h4>
                    <span href="mailto:funeralhelp@tahts.org" class="btn-link text-white">funeralhelp@tahts.org</span>
                  </div>
                  <span class="text-white btn-link">Frequently Asked Questions  <ArrowRightOutlined />
                  </span>
                </a>
              </Col>

              <Col lg={5} md={7} className='mb-3'>
                <a class="sigma_service style-1 primary-bg">

                  <div class="sigma_service-body">
                    <h2 class="text-white">Medicare</h2>
                    <h4 class="text-white">Prescription FAQ</h4>
                    <p class="text-white-para">Prescription Medication Frequently Asked Questions.</p>
                    <h4 class="text-white">Contact:</h4>
                    <span href="mailto:medicareadvise@tahts.org" class="btn-link text-white">medicareadvise@tahts.org</span>
                  </div>
                  <span class="btn-link text-white">Prescription Medication FAQ <ArrowRightOutlined />
                  </span>
                </a>
              </Col>

              <Col lg={5} md={7} className='mb-3'>
                <a class="sigma_service style-1 secondary-bg">

                  <div class="sigma_service-body">
                    <h2 class="text-white">Disaster relief</h2>
                    <h4 class="text-white">DRC FAQ</h4>
                    <p class="text-white-para">TAHTS is a registered 501(c)(3) nonprofit organization</p>
                    <h4 class="text-white">Contact:</h4>
                    <span href="mailto:disasterrelief@tahts.org" class="btn-link text-white">disasterrelief@tahts.org</span>
                  </div>
                  <span class="text-white btn-link">Frequently Asked Questions  <ArrowRightOutlined />
                  </span>
                </a>
              </Col>

              <Col lg={4} md={7} className='mb-3'>
                <a class="sigma_service style-1 primary-bg">

                  <div class="sigma_service-body">
                    <h2 class="text-white">Outreach committee</h2>
                    <h4 class="text-white">DRC FAQ</h4>
                    <p class="text-white-para">TAHTS is a registered 501(c)(3) nonprofit organization</p>
                    <h4 class="text-white">Contact:</h4>
                    <span href="mailto:outreach@tahts.org" class="btn-link text-white">outreach@tahts.org</span>
                  </div>
                  <span class="text-white btn-link">Frequently Asked Questions <ArrowRightOutlined />
                  </span>
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/* IMPORTANT INFORMATION ends */}

      {/* NEWS LETTER Start */}
      <Container style={{ marginBottom: "20px", marginTop: "20px" }}>
        <div className='section-title '>
          <h1 className='title-news'>NEWS LETTER</h1>
          <div className="button-container">
            <Row>
              <Col lg={5}>
                <button className="red-button">
                  Sign up for News Letter
                </button>
              </Col>
              <Col lg={5}>
                <button className="voilet-button" href="/newletterpage">
                  View all News Letter
                </button>
              </Col>
            </Row>
          </div>
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} lg={6}>
            <a href="http://tahts.org/wp-content/uploads/2022/02/RTP-Spandan-Jan22_rev1-3.pdf" className="sigma_service style-3">
              <div className="sigma_service-thumb">
                <Images src="images/newsLetters/letter/1.png"></Images>
              </div>
              <div className="sigma_service-body" style={{ textAlign: 'center' }}>
                <h3 className='news-text'>Jan. 2022</h3>
              </div>
            </a>
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <a href="http://tahts.org/wp-content/uploads/2021/12/RTPSpandan_202111.pdf" className="sigma_service style-3">
              <div className="sigma_service-thumb">
                <Images src="images/newsLetters/letter/2.png"></Images>
              </div>
              <div className="sigma_service-body" style={{ textAlign: 'center' }}>
                <h3 className='news-text'>Nov. 2021</h3>
              </div>
            </a>
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <a href="http://tahts.org/wp-content/uploads/2021/09/RTP_Spandan_Sept2021.pdf" className="sigma_service style-3">
              <div className="sigma_service-thumb">
                <Images src="images/newsLetters/letter/3.png"></Images>
              </div>
              <div className="sigma_service-body" style={{ textAlign: 'center' }}>
                <h3 className='news-text'>Sept. 2021</h3>
              </div>
            </a>
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <a href="http://tahts.org/wp-content/uploads/2021/07/RTP_Spandan_July2021-1.pdf" className="sigma_service style-3">
              <div className="sigma_service-thumb">
                <Images src="images/newsLetters/letter/4.png"></Images>
              </div>
              <div className="sigma_service-body" style={{ textAlign: 'center' }}>
                <h3 className='news-text'>July 2021</h3>
              </div>
            </a>
          </Col>
        </Row>
      </Container>
      {/* NEWS LETTER End  */}

      {/*About starts */}
      <section className='information-background' >
        <div className='section section-lg section-shaped pb-80'style={{backgroundImage:'url(images/banner/2.png)'}} >
          <Container style={{ marginBottom: "20px", marginTop: "20px" }}>
          <div className="slider-nav">
            <button className="prev" onClick={showPrevColumns}>&lt;</button>
            <button className="next" onClick={showNextColumns}>&gt;</button>
          </div>
            <Row gutter={[16, 16]} className="slider-content">
            {columnsData.slice(startIndex, startIndex + columnsPerPage).map((column, index) => (
              <>
               <Col key={index} lg={12} md={12} className='mb-4'>
                {column}
              </Col>
              </>
            ))}
            </Row>
          </Container>
        </div>
      </section>
      {/* About ends */}


      {/* Article Start  */}
      <Container style={{ marginBottom: '20px', marginTop: '20px' }}>
        <Row xs={1} sm={2}>
          <Col className='image-card' style={{ marginRight: "1rem" }}>
            <div>
              <Images src="images/pictures/2019-day1/1.jpg" onClick={() => navigate(`/healthifair1`)} />
              <div>
                <div className="image-card-text">
                  <h5><CalendarOutlined /> 2019</h5>
                </div>
                <h3 className="image-card-text">Health Fair Day - 1</h3>
              </div>
            </div>
          </Col>
          <Col className='image-card'>
            <div>
              <Images src="images/pictures/2019-day2/1.jpg" onClick={() => navigate(`/healthifair2`)} />
              <div>
                <div className="image-card-text">
                  <h5><CalendarOutlined /> 2019</h5>
                </div>
                <h3 className="image-card-text">Health Fair Day - 2</h3>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div>
        <BackButton />
      </div>

      {/* Article Ends */}
      <Footer />
    </>
  );
}

export default Home;
