import React from "react";
import { Images } from './Images';

const Footer = () => {
    return (
        <div className="footer-box">
            <div className="container-footer">
                <div class="footer-text">
                    Copyright © 2023 by TAHT. All Rights Reserved.
                </div>

                <div class="footer-text">
                    <Images src={'/images/footer/1.png'} />
                </div>
            </div>
        </div>
    );
}

export default Footer;