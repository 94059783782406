import React, { useState, useEffect } from 'react';
import { ArrowUpOutlined } from '@ant-design/icons';

const BackButton = () => {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 200) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button
      className={`back-to-top-button ${showButton ? 'show' : ''}`}
      onClick={handleBackToTop}
    >
      <ArrowUpOutlined />
    </button>
  );
};

export default BackButton;
