import React from 'react';
import Header from '../baseComponents/Header';
import Footer from '../baseComponents/Footer';
import { Col, Row } from 'antd';
import { Images } from '../baseComponents/Images';
import { Container } from 'react-bootstrap';
import BackButton from '../baseComponents/BackButton';
const NewLetterPage = () => {
  return (
    <>
      <Header />

      {/* banner start */}
      <div className="banner dark-overlay dark-overlay-2" style={{ backgroundImage: 'url(images/banner/3.jpg)' }}>
        <div className="container">
          <div className="banner-news">
            <h1>NEWS LETTERS</h1>
          </div>
        </div>
      </div>
      {/* banner ends */}

      <Container style={{ marginBottom: "20px", marginTop: "20px" }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} lg={6}>
            <a href="http://tahts.org/wp-content/uploads/2022/02/RTP-Spandan-Jan22_rev1-3.pdf" className="sigma_service style-3">
              <div className="sigma_service-thumb">
                <Images src="images/newsLetters/letter/1.png"></Images>
              </div>
              <div className="sigma_service-body" style={{ textAlign: 'center' }}>
                <h3 className='news-text'>Jan. 2022</h3>
              </div>
            </a>
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <a href="http://tahts.org/wp-content/uploads/2021/12/RTPSpandan_202111.pdf" className="sigma_service style-3">
              <div className="sigma_service-thumb">
                <Images src="images/newsLetters/letter/2.png"></Images>
              </div>
              <div className="sigma_service-body" style={{ textAlign: 'center' }}>
                <h3 className='news-text'>Nov. 2021</h3>
              </div>
            </a>
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <a href="http://tahts.org/wp-content/uploads/2021/09/RTP_Spandan_Sept2021.pdf" className="sigma_service style-3">
              <div className="sigma_service-thumb">
                <Images src="images/newsLetters/letter/3.png"></Images>
              </div>
              <div className="sigma_service-body" style={{ textAlign: 'center' }}>
                <h3 className='news-text'>Sept. 2021</h3>
              </div>
            </a>
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <a href="http://tahts.org/wp-content/uploads/2021/07/RTP_Spandan_July2021-1.pdf" className="sigma_service style-3">
              <div className="sigma_service-thumb">
                <Images src="images/newsLetters/letter/4.png"></Images>
              </div>
              <div className="sigma_service-body" style={{ textAlign: 'center' }}>
                <h3 className='news-text'>July 2021</h3>
              </div>
            </a>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} lg={6}>
            <a href="http://tahts.org/wp-content/uploads/2021/06/RTP_Spandan_May2021_rev4-_1.pdf" className="sigma_service style-3">
              <div className="sigma_service-thumb">
                <Images src="images/newsLetters/letter/5.png"></Images>
              </div>
              <div className="sigma_service-body" style={{ textAlign: 'center' }}>
                <h3 className='news-text'>May. 2021</h3>
              </div>
            </a>
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <a href="http://tahts.org/wp-content/uploads/2021/03/RTP-Spandan-Mar2021-rev1.pdf" className="sigma_service style-3">
              <div className="sigma_service-thumb">
                <Images src="images/newsLetters/letter/6.png"></Images>
              </div>
              <div className="sigma_service-body" style={{ textAlign: 'center' }}>
                <h3 className='news-text'>Mar. 2021</h3>
              </div>
            </a>
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <a href="http://tahts.org/wp-content/uploads/2021/01/RTP_Spandan_JAN_2021.pdf" className="sigma_service style-3">
              <div className="sigma_service-thumb">
                <Images src="images/newsLetters/letter/7.png"></Images>
              </div>
              <div className="sigma_service-body" style={{ textAlign: 'center' }}>
                <h3 className="news-text">Jan. 2021 </h3>
              </div>
            </a>
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <a href="http://tahts.org/wp-content/uploads/2020/11/RTP_Spandan_NOV2020.pd" className="sigma_service style-3">
              <div className="sigma_service-thumb">
                <Images src="images/newsLetters/letter/8.png"></Images>
              </div>
              <div className="sigma_service-body" style={{ textAlign: 'center' }}>
                <h3 className="news-text">Nov. 2020</h3>
              </div>
            </a>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} lg={6}>
            <a href="http://tahts.org/wp-content/uploads/2020/10/TaHT_Newsletter_Sept_2020-_small_.pdf" className="sigma_service style-3">
              <div className="sigma_service-thumb">
                <Images src="images/newsLetters/letter/9.png"></Images>
              </div>
              <div className="sigma_service-body" style={{ textAlign: 'center' }}>
                <h3 className='news-text'>Sept. 2020 </h3>
              </div>
            </a>
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <a href="http://tahts.org/wp-content/uploads/2020/07/TAHTS-Newsletter-July-2020.pdf" className="sigma_service style-3">
              <div className="sigma_service-thumb">
                <Images src="images/newsLetters/letter/10.png"></Images>
              </div>
              <div className="sigma_service-body" style={{ textAlign: 'center' }}>
                <h3 className='news-text'>July 2020 </h3>
              </div>
            </a>
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <a href="http://tahts.org/wp-content/uploads/2020/05/TAHTS_Newsletter_2020_05_final.pdf" className="sigma_service style-3">
              <div className="sigma_service-thumb">
                <Images src="images/newsLetters/letter/11.png"></Images>
              </div>
              <div className="sigma_service-body" style={{ textAlign: 'center' }}>
                <h3 className='news-text'>May 2020</h3>
              </div>
            </a>
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <a href="http://tahts.org/wp-content/uploads/2020/04/TAHTs-Newsletter-2020-03-final.pdf" className="sigma_service style-3">
              <div className="sigma_service-thumb">
                <Images src="images/newsLetters/letter/12.png"></Images>
              </div>
              <div className="sigma_service-body" style={{ textAlign: 'center' }}>
                <h3 className='news-text'>Mar. 2020</h3>
              </div>
            </a>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} lg={6}>
            <a href="http://tahts.org/wp-content/uploads/2020/04/TAHTs-News-letter-Dec-2019-V1.pdf" className="sigma_service style-3">
              <div className="sigma_service-thumb">
                <Images src="images/newsLetters/letter/13.png"></Images>
              </div>
              <div className="sigma_service-body" style={{ textAlign: 'center' }}>
                <h3 className='news-text'>Dec. 2019 </h3>
              </div>
            </a>
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <a href="http://tahts.org/wp-content/uploads/2020/04/TAHTs_News_letter_Sep_2019_V2.pdf" className="sigma_service style-3">
              <div className="sigma_service-thumb">
                <Images src="images/newsLetters/letter/14.png"></Images>
              </div>
              <div className="sigma_service-body" style={{ textAlign: 'center' }}>
                <h3 className='news-text'>Sept. 2019 </h3>
              </div>
            </a>
          </Col>
        </Row>
      </Container>
      <div>
        <BackButton />
      </div>
      <Footer />
    </>
  );
}

export default NewLetterPage;
