import React from 'react';
import { useState } from 'react';
import Header from '../baseComponents/Header';
import Footer from '../baseComponents/Footer';
import { Images } from '../baseComponents/Images';

const Hospitality = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % 5);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + 5) % 5);
  };
  return (
    <>
      <Header />
      {/* banner start */}
      <div className="banner dark-overlay dark-overlay-2" style={{ backgroundImage: 'url(images/banner/3.jpg)' }}>
        <div className="container">
          <div className="banner-hospitality">
            <h1>Hospitality Committee</h1>
          </div>
        </div>
      </div>
      {/* banner ends */}

      <div className="slider">
        <div className="slides" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
          <div className="slide">
            <Images className={"image"} src="/images/banner/hindu/slide_1.jpg" />
          </div>
          <div className="slide">
            <Images className={"image"} src="/images/banner/hindu/slide_2.jpg" />
          </div>
          <div className="slide">
            <Images className={"image"} src="/images/banner/hindu/slide_3.jpg" />
          </div>
          <div className="slide">
            <Images className={"image"} src="/images/banner/hindu/slide_4.jpg" />
          </div>
          <div className="slide">
            <Images className={"image"} src="/images/banner/hindu/slide_5.jpg" />
          </div>
        </div>
        <button className="prev" onClick={prevSlide}>
          &lt;
        </button>
        <button className="next" onClick={nextSlide}>
          &gt;
        </button>
      </div>

      <Footer />
    </>


  );
}

export default Hospitality;
