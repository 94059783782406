import React from 'react';
import Header from '../baseComponents/Header';
import Footer from '../baseComponents/Footer';
import { Form, Input, Select, Button, Radio, Space } from 'antd';
import { Container } from 'react-bootstrap';

const { Option } = Select;
const Donation = () => {
  return (
    <>
      <Header />
      {/* banner start */}
      <div className="banner dark-overlay dark-overlay-2" style={{ backgroundImage: 'url(images/banner/6.png)' }}>
        <div className="container">
          <div className="banner-donation">
            <h1>DONATE TO HELP</h1>
          </div>
        </div>
      </div>
      {/* banner ends */}
      <Container className="my-5">
        <Form>
          <div className="form-row sigma_donation-form">
            <div className="col-12">
              <div className="form-group mb-4">
                <h5 className='form-text mb-3'>Donator Details</h5>
                <h6 className="form-text">Name</h6>
                <div className="row">
                  <div className="col-lg-6">
                    <Input
                      name="name"
                      rules={[{ required: true, message: 'Please enter your name' }]}
                      placeholder="First"
                      className="form-control mt-2"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="form-group mb-4">
                <h6 className='form-text'>Email</h6>
                <div className="row">
                  <div className="col-lg-6">
                    <Input
                      name="email"
                      rules={[
                        { required: true, message: 'Please enter your email' },
                        { type: 'email', message: 'Please enter a valid email' },
                      ]}
                      placeholder="Email"
                      className="form-control mt-2"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="form-group mb-4">
                <h5 className="form-text">Donation Amount</h5>
                <div className="input-group mt-2">
                  <div className="input-group-prepend">
                    <Space.Compact style={{width:"100%"}}>
                    <Button className="form-control-btn ms-0">  $</Button>
                      <Input
                    name="donationAmount"
                    rules={[{ required: true, message: 'Please enter the donation amount' }]}
                    className="form-control mt-2"
                    placeholder="$100"
                  />
                    </Space.Compact>
                  </div>
                </div>
                <ul className="sigma_select-amount">
                  <li>$15.00</li>
                  <li>$25.00</li>
                  <li>$45.00</li>
                  <li className="active">$100.00</li>
                  <li>$500.00</li>
                </ul>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group mb-4">
                <h5 className="form-text">Payment Method</h5>
                <div className="d-flex align-items-center mt-3">
                  <Radio.Group name="paymentMethod" rules={[{ required: true, message: 'Please select a payment method' }]}>
                    <Radio value="Online Payment">Online Payment</Radio>
                    <Radio value="Offline Payment">Offline Payment</Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group mb-4">
                <h5 className='form-text'>Campaigns</h5>
                <Select name="campaign" rules={[{ required: true, message: 'Please select a campaign' }]} className="form-control mt-3">
                  <Option value="1">Education for Every Child - (Target: 10000.00)</Option>
                  <Option value="2">Education for Every Child - (Target: 10000.00)</Option>
                  <Option value="3">Education for Every Child - (Target: 15000.00)</Option>
                  <Option value="4">Education for Every Child - (Target: 25000.00)</Option>
                </Select>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group mb-4">
                <h5 className='form-text'>Message</h5>
                <Input.TextArea name="message" className="form-control mt-2" placeholder="Enter Message" rows={6} />
              </div>
            </div>

            <div className="col-lg-12 mb-4">
              <Button className="btn-custom" href="/donation" style={{ color: 'white' }}>
                Donate Now
              </Button>
            </div>
          </div>
        </Form>
      </Container>

      <Footer />
    </>


  );
}

export default Donation;
