import React from 'react';
import Header from '../baseComponents/Header';
import Footer from '../baseComponents/Footer';
import { Col, Row, Card, Input } from 'antd';
import { Container } from 'react-bootstrap';
const Community = () => {
  return (
    <>
      <Header />
      {/* banner start */}
      <div className="banner dark-overlay dark-overlay-2" style={{ backgroundImage: 'url(images/banner/4.jpg)' }}>
        <div className="container">
          <div className="banner-community">
            <h1>Community Support Groups</h1>
          </div>
        </div>
      </div>

      <Container className='mt-5'>
        <Row>
          {/* Col 1 */}
          <Col lg={7} className='card-adjustment'>
            <div className="sigma_icon-block text-center light icon-block-7">
              <div className="sigma_icon-block-content">
                <h5>Cancer Support</h5>
                <p>Support, connect and educate cancer patients.</p>
                <p>
                  contact:{' '}
                  <a className='mail-text' href="mailto:cancersupport@tahts.org" style={{ marginBottom: "1rem" }}>
                    cancersupport@tahts.org
                  </a>
                </p>
              </div>
            </div>
          </Col>

          {/* Col 2 */}
          <Col lg={7} className='card-adjustment'>
            <div className="sigma_icon-block text-center light icon-block-7">
              <div className="sigma_icon-block-content">
                <div className="block-item-text">
                  <input type="checkbox" hidden className="read-more-state" id="read-more" />
                  <div className="read-more-wrap">
                    <h5 style={{ color: '#7e4555' }}>Funeral Help</h5>
                    <p>Full help with arrangement for funeral services, puja and later help.</p>
                    <p >  Click here to view:{' '}</p>

                    <a className='mail-text'
                      href="http://tahts.org/wp-content/uploads/2019/12/Guide-to-Hindu-Funeral.pdf"
                    >
                      Guide to Hindu Funeral
                    </a>

                    <p className="read-more-target">
                      contact:{' '}
                      <a className='mail-text' href="mailto:funeralhelp@tahts.org">
                        funeralhelp@tahts.org
                      </a>
                    </p>

                  </div>
                  <label htmlFor="read-more" className="read-more-trigger_closed" style={{ marginBottom: "28px" }}>
                    Read More
                  </label>
                  <label htmlFor="read-more" className="read-more-trigger_opened">
                    Read Less
                  </label>
                </div>
              </div>
            </div>
          </Col>

          {/* Col 3 */}
          <Col lg={7} className='card-adjustment'>
            <div className="sigma_icon-block text-center light icon-block-7" >
              <div className="sigma_icon-block-content">
                <h5>Hospital Help</h5>
                <p>Visit and support patients in the hospitals.</p>
                <p>
                  contact:{' '}
                  <a className='mail-text' href="mailto:hospitalhelp@tahts.org" style={{ marginBottom: "1rem" }}>
                    hospitalhelp@tahts.org
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          {/* Col 4 */}
          <Col lg={7} className='card-adjustment'>
            <div className="sigma_icon-block text-center light icon-block-7" >
              <div className="sigma_icon-block-content">
                <h5 style={{ color: '#7e4555' }}>Matrimonial Help</h5>
                <p>
                  Confidentially and discretely connect men/women and families for marriage.
                </p>
                <p>
                  <a className='mail-text' href="" download>Download the matrimonial application.</a>
                </p>
                <p>contact:
                  <a className='mail-text' href="mailto:matrimonial@tahts.org">matrimonial@tahts.org</a>
                </p>
              </div>
            </div>
          </Col>

          {/* Col 5 */}
          <Col lg={7} className='card-adjustment' >
            <div className="sigma_icon-block text-center light icon-block-7" >
              <div className="sigma_icon-block-content">
                <div className="block-item-text">
                  <input type="checkbox" hidden className="read-more-state1" id="read-more1" />
                  <div className="read-more-wrap1">
                    <h5>Medicare/</h5>
                    <h5>Medicaid Help</h5>
                    <p>
                      Advice and help in choosing and applying the right program.
                    </p>
                    <p className="read-more-target1">
                      <p>
                        Click here to view:
                        <a className='mail-text' href="http://tahts.org/wp-content/uploads/2019/01/MedicareFAQ.pdf" >Medicare Frequently Asked Questions</a>
                      </p>
                      Click here to view:
                      <a className='mail-text' href="http://tahts.org/wp-content/uploads/2019/01/Prescription_Medication-FAQ.pdf" >Prescription Medication Frequently Asked Questions</a>
                      contact:
                      <a className='mail-text' href="mailto:medicareadvice@tahts.org" >medicareadvice@tahts.org</a>
                    </p>
                  </div>
                  <label htmlFor="read-more1" className="read-more-trigger_closed1">Read More</label>
                  <label htmlFor="read-more1" className="read-more-trigger_opened1">Read Less</label>
                </div>
              </div>
            </div>
          </Col>

          {/* Col 6 */}
          <Col lg={7} className='card-adjustment'>
            <div className="sigma_icon-block text-center light icon-block-7" >
              <div className="sigma_icon-block-content">
                <h5 style={{ color: '#7e4555' }}>New Comers Help</h5>
                <p>
                  Help finding community connections, schools and housing etc.
                </p>
                <p className="">contact:
                  <a className='mail-text' href="mailto:newcomers@tahts.org" style={{ marginBottom: "1rem" }}>newcomers@tahts.org</a>
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          {/* Col 7 */}
          <Col lg={7} className='card-adjustment'>
            <div className="sigma_icon-block text-center light icon-block-7" >
              <div className="sigma_icon-block-content">
                <h5>Senior Help</h5>
                <p>
                  Help with transport, food, visiting prevent senior abuse, buddy system
                </p>
                <p className="mt-2">contact:
                  <a className='mail-text' href="mailto:seniorhelp@tahts.org" style={{ marginBottom: "2rem" }}>seniorhelp@tahts.org</a>
                </p>
              </div>
            </div>
          </Col>

          {/* Col 8 */}
          <Col lg={7} className='card-adjustment'>
            <div className="sigma_icon-block text-center light icon-block-7" >
              <div className="sigma_icon-block-content">
                <div className="block-item-text">
                  <input type="checkbox" hidden className="read-more-state2" id="read-more2" />
                  <div className="read-more-wrap2">
                    <h5 style={{ color: '#7e4555' }}>Tax Help</h5>
                    <p>Certified tax experts will help you file taxes without charges.
                      Retired and Senior people can check out the sites where they can get help with the tax
                      return.
                    </p>
                    <p> Please click here: </p>
                    <p className="read-more-target2">
                      RSVP Free Tax Preparation sites.
                      <p>contact:</p>
                      <a className='mail-text' href="mailto:taxpreparation@tahts.org" >taxpreparation@tahts.org</a>
                    </p>
                  </div>
                  <label htmlFor="read-more2" className="read-more-trigger_closed2">Read More</label>
                  <label htmlFor="read-more2" className="read-more-trigger_opened2">Read Less</label>
                </div>
              </div>
            </div>
          </Col>

          {/* Col 9 */}
          <Col lg={7} className='card-adjustment'>
            <div className="sigma_icon-block text-center light icon-block-7" >
              <div className="sigma_icon-block-content">
                <h5>Teenage, Marriage & Family Issues</h5>
                <p>
                  Help teenage children and parents, marriage problems, alcohol and drugs.
                </p>
                <p className="mt-1">contact:
                  <a className='mail-text' href="mailto:familyproblem@tahts.org">familyproblem@tahts.org</a>
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          {/* Col 10 */}
          <Col lg={7} className='card-adjustment'>
            <div className="sigma_icon-block text-center light icon-block-7" >
              <div className="sigma_icon-block-content">
                <h5 style={{ color: '#7e4555' }}>Voice of India</h5>
                <p>Aim of the committee is "To inform the public on the facts and highlight the fake
                  news about India
                  and its
                  government in US media".
                </p>
                <p>contact:
                  <a className='mail-text' href="mailto:voiceofindia@tahts.org" style={{ marginBottom: "1rem" }}>voiceofindia@tahts.org</a>
                </p>
              </div>
            </div>
          </Col>

          {/* Col 11 */}
          <Col lg={7} className='card-adjustment'>
            <div className="sigma_icon-block text-center light icon-block-7" >
              <div className="sigma_icon-block-content">
                <div className="block-item-text">
                  <input type="checkbox" hidden className="read-more-state3" id="read-more3" />
                  <div className="read-more-wrap3">
                    <h5>Disaster Relief</h5>
                    <p>
                      <strong style={{ color: '#7e4555' }}>Mission:</strong>
                      The Disaster Relief Committee of TAHT strives to complement, restore and strengthen
                      our
                      community and governments' ability to plan for recovery by engaging the volunteers,
                      donors, and partners to bring relief to
                    </p>
                    <p className="read-more-target3">
                      the disaster victims.
                      <strong style={{ color: '#7e4555' }}>Vision:</strong>
                      The Disaster Relief Committee of TAHT would like to mobilize its volunteers and donors
                      in a timely manner to help victims and families in their time of need.
                      <a
                        style={{ fontFamily: 'arial', color: '#ffffff !important', fontSize: '14px', textShadow: '1px 1px 0px #7CACDE', boxShadow: '1px 1px 1px #BEE2F9', padding: '10px 25px', MozBorderRadius: '10px', WebkitBorderRadius: '10px', borderRadius: '10px', border: '2px solid #3866A3', backgroundColor: '#e74c3c' }}
                        href="https://forms.gle/hAQ1iHgr6caYfCvd7">Volunteer
                        Registration
                      </a>
                      We would be grateful if you would continue your generous support to our organization
                      by
                      contributing to our Disaster Relief fund.
                      <a
                        style={{ fontFamily: 'arial', color: '#ffffff !important', fontSize: '14px', textShadow: '1px 1px 0px #7CACDE', boxShadow: '1px 1px 1px #BEE2F9', padding: '10px 25px', MozBorderRadius: '10px', WebkitBorderRadius: '10px', borderRadius: '10px', border: '2px solid #3866A3', background: 'green' }}
                        href="https://www.paypal.com/donate/?hosted_button_id=VYXXT3SGGMUNE">Click here to Donate!
                      </a>
                      <strong>contact</strong>
                      <a className='mail-text' href="mailto:disasterrelief@tahts.org" >disasterrelief@tahts.org
                      </a>
                    </p>
                  </div>
                  <label htmlFor="read-more3" className="read-more-trigger_closed3">Read More</label>
                  <label htmlFor="read-more3" className="read-more-trigger_opened3">Read Less</label>
                </div>
              </div>
            </div>
          </Col>

          {/* Col 12 */}
          <Col lg={7} className='card-adjustment'>
            <div className="sigma_icon-block text-center light icon-block-7" >
              <div className="sigma_icon-block-content">
                <h5 style={{ color: '#7e4555' }}>To get help or to volunteer:</h5>
                <p>Contact the email address of the sub-committee or send mail to
                </p>
                <p>contact:
                  <a className='mail-text' href="mailto:communitysupport@tahts.org" >communitysupport@tahts.org</a>
                </p>
                <p>or
                  Call: (919) 208-0695</p>
              </div>
            </div>
          </Col>
        </Row>

      </Container >

      <Footer />
    </>


  );
}

export default Community;
