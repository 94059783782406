import React from 'react';
import Header from '../baseComponents/Header';
import Footer from '../baseComponents/Footer';
import { Col, Row, Card, Input } from 'antd';
import { UserOutlined, MailOutlined, EditOutlined } from '@ant-design/icons';
import { Form, Button } from 'antd';
import { Container } from 'react-bootstrap';

const ContactUs = () => {

  return (
    <>
      <Header />
      {/* banner start */}

      <div className="banner dark-overlay dark-overlay-2" style={{ backgroundImage: 'url(images/banner/6.png)' }}>
        <div className="container">
          <div className="banner-contact">
            <h1>Contact Us</h1>
          </div>
        </div>
      </div>
      {/* banner ends */}

      <Container>
        <Form
          name="contactForm"
          className="sigma_box box-lg m-0 mf_form_validate ajax_submit"
        >
          <Row >
            <Col lg={7} style={{ marginRight: "2rem" }}>
              <Input
                name="name"
                rules={[{ required: true, message: 'Please enter your full name' }]}
                placeholder="Full Name"
                className="form-control dark"
                // prefix={<UserOutlined />}
                style={{ background: '#ededed' }}
              />
            </Col>
            <Col lg={8} style={{ marginRight: "2rem" }}>
              <Input
                name="email"
                rules={[
                  { required: true, message: 'Please enter your email address' },
                  { type: 'email', message: 'Invalid email address' },
                ]}
                style={{ backgroundColor: '#ededed' }}
                placeholder="Email Address"
                className="form-control dark"
                // prefix={<MailOutlined />}
                
              />
            </Col>
            <Col lg={7}>
              <Input
                name="subject"
                rules={[{ required: true, message: 'Please enter the subject' }]}
                style={{ backgroundColor: '#ededed' }}
                placeholder="Subject"
                className="form-control dark"
                // prefix={<EditOutlined />}
              />
            </Col>
          </Row>
          <div >
            <Input.TextArea
              name="message"
              rules={[{ required: true, message: 'Please enter your message' }]}
              placeholder="Enter Message"
              rows={5}
              className="form-control dark"
              style={{ backgroundColor: '#ededed' }}
            />
          </div>
          <div>
            <Button className='btn-custom' href='/donation' style={{ color: "white", marginTop: "20px" }}>Submit Now</Button>
          </div>
        </Form>
      </Container>


      <section className="px-5 py-4" >
        <Container>
          <Row >
            {/* Col 1 */}
            <Col lg={7} style={{ marginRight: "2rem" }} >
              <div className="sigma_icon-block text-center light icon-block-7">
                <div className="sigma_icon-block-content">
                  <h5>Contact Us</h5>
                  <p>You may contact us via email with any questions you may have. Someone will get back to you as soon as possible.</p>
                  <p>By email: <a href="mailto:customerservice@tahts.org"style={{ marginBottom: "1rem" }}>customerservice@tahts.org</a></p>
                </div>
              </div>
            </Col>

            {/* Col 2 */}
            <Col lg={8} style={{ marginRight: "2rem" }}>
              <div className="sigma_icon-block text-center light icon-block-7">
                <div className="sigma_icon-block-content">
                  <div className='middle-card-text'>
                    <p>COMMITTEES</p>
                    <p>TAHTS COMMITTEE</p>
                    <p>HEALTH FAIR COMMITTEE </p>
                    <p style={{ marginBottom: "1.5rem" }}>KID SUMMIT COMMITTEE</p>
                  </div>
                </div>
              </div>
            </Col>

            {/* Col 3 */}
            <Col lg={7}>
              <div className="sigma_icon-block text-center light icon-block-7">
                <div className="sigma_icon-block-content">
                  <h5>Community Support Group:</h5>
                  <p>
                    To get help or to volunteer: <br />
                    Phone: (919) 208-0695
                  </p>
                  <p>By email: <a href="mailto:communitysupport@tahts.org">communitysupport@tahts.org</a></p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>


  );
}

export default ContactUs;
