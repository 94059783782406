import React from 'react';
import Header from '../baseComponents/Header';
import Footer from '../baseComponents/Footer';
import { Col, Row } from 'antd';
import { Images } from '../baseComponents/Images';

const AboutUs = () => {
  return (
    <>
      <Header />
      {/* banner start */}
      <div className="banner dark-overlay dark-overlay-2" style={{ backgroundImage: 'url(images/banner/3.jpg)' }}>
        <div className="container">
          <div className="banner-about">
            <h1>About Us</h1>
          </div>
        </div>
      </div>
      {/* banner ends */}

        <div className='entry-content'>
          <Row>
            <Col lg={15}>
              <div className='title'>
                <h1>About Us</h1>
                <p className='subtitle'> ॐ The mission of the Triangle Area Hindu Temples</p>
              </div>

              <p className='blockquote bg-transparent'> To provide an independent platform to bring together triangle area community support under one umbrella for community and humanitarian services, religious discussion, community events, fundraising and spiritual discourses, to foster Hindu Culture and Values.</p>
              <p className='blockquote bg-transparent'> To solidify individual and independent resources for the community service activities, thus bringing the entire Indian community together under one roof.</p>
              {/* <p className='blockquote bg-transparent'> The events are conducted to address the welfare and humanitarian needs of the society.</p> */}
            </Col>

            <Col lg={8}>
              <Images className={"image"} src="/images/about/1.jpg" />
            </Col>
          </Row>
        </div>
      <Footer />
    </>
  );
}

export default AboutUs;
