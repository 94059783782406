import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';

const Slideshow = ({ imageCount, imagePath, interval = 3000 }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);
  const imagesPerPage = 4;

  useEffect(() => {
    const generateImagePaths = () => {
      const generatedImages = [];
      for (let i = 1; i <= imageCount; i++) {
        generatedImages.push(`${process.env.PUBLIC_URL}/${imagePath}/${i}.jpg`);
      }
      return generatedImages;
    };

    const images = generateImagePaths();

    const pageArray = [];
    for (let i = 0; i < images.length; i += imagesPerPage) {
      pageArray.push(images.slice(i, i + imagesPerPage));
    }
    setPages(pageArray);

    const slideInterval = setInterval(() => {
      setCurrentPage((prevPage) => (prevPage + 1) % pageArray.length);
    }, interval);

    return () => {
      clearInterval(slideInterval);
    };
  }, [imageCount, imagePath, interval]);

  if (imageCount <= 0) {
    return <div>No images available.</div>;
  }

  return (
    <div className='slide-show'>
      {pages.length > 0 && (
        <>
          {pages[currentPage].map((image, index) => (
            <LazyLoad key={index} height={200} offset={100}>
              <img className='image-slideshow' src={image} alt={`Image ${index}`} />
            </LazyLoad>
          ))}
        </>
      )}
    </div>
  );
};

export default Slideshow;
