import React from 'react';
import Header from '../baseComponents/Header';
import Footer from '../baseComponents/Footer';
import { Container } from 'react-bootstrap';
import {  Timeline } from 'antd';

const HealthFair2023 = () => {
  return (
    <>
      <Header />
      {/* banner start */}
      <div className="banner dark-overlay dark-overlay-2" style={{ backgroundImage: 'url(images/banner/3.jpg)' }}>
        <div className="container">
          <div className="banner-healthfair">
            <h1>HEALTH FAIR 2023</h1>
          </div>
        </div>
      </div>
      {/* banner ends */}

      <Container style={{ marginBottom: "20px", marginTop: "20px" }}>
        <Timeline mode="alternate" >

          <Timeline.Item style={{ marginBottom: "10rem" }} label={<><h2 className='header-text'>HEALTH FAIR 2023</h2>
            <p className='para-text'>Triangle Area Hindu Temples and Triangle Indian-American Physicians Society Proudly Present</p>
          </>}>
            <h2 className='header-text'>14th Annual Health Fair</h2>

          </Timeline.Item>

          <Timeline.Item style={{ marginBottom: "10rem" }} label={<><div>
            <h2 className='header-text'>Open to all, irrespective of Race, Religion, or Nationality.</h2>
            <p className='para-text'>May 01, 2023 to May 15, 2023</p>
            <p className='para-text'>Time: 8:00AM to 11:00AM</p>
            <p className='para-text'>At any LabCorp. service center</p>
          </div>
          </>}>
            <h2 className='header-text'>Blood draw day (Select any day)</h2>

          </Timeline.Item>

          <Timeline.Item style={{ marginBottom: "10rem" }} label={<><div>
            <h2 className='header-text'>Open to all, irrespective of Race, Religion, or Nationality.</h2>
            <p className='para-text'>Saturday, May 20, 2023</p>
            <p className='para-text'>Time: 9:00 AM to 1:00 PM</p>
          </div>
          </>}>
            <h2 className='header-text'>Health Fair Day</h2>

          </Timeline.Item>

          <Timeline.Item style={{ marginBottom: "10rem" }} label={<> <div>  <h2 className='header-text'>Registration Required</h2>
            <p className='para-text'>Blood Draw Consultation with doctors</p>
            <p className='para-text'>Blood Drive Hands-on CPR</p>
            <p className='para-text'>Medicare Information Session</p>
            <p className='para-text'>Flu Shots</p>
            <p className='para-text'>A living will service</p></div>
          </>}>
            <h2 className='header-text'>(Registration fee $25. Non-Refundable ):</h2>

          </Timeline.Item>

          <Timeline.Item style={{ marginBottom: "10rem" }} label={<><div><h2 className='header-text'>Medical Specialties:</h2>
            <p className='para-text'>Internal Medicine, Pediatric, Gastroenterologist, Neurology, Depression, Cardiology, Cancer specialist, Carotid Doppler, Eye checkup, Blood pressure</p></div> </>}>
            <h2 className='header-text'>Registration is open.</h2>
            <a href="https://tahts.org/healthfair-registration-form/">Click here to register for Health Fair 2023</a>
          </Timeline.Item>

        </Timeline>
      </Container>

      <Footer />
    </>
  );
}

export default HealthFair2023;
