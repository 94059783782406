import React from 'react';
import { classNames } from '../utilities/commonFunction';
export const Logo = (
    {
        id,
        name,
        className,
        src,
        onClick,
        onError,
    }
) => {
    return (
        <img
            id={id}
            name={name}
            className={classNames('logo', className ? className : '')}
            src={src}
            onClick={onClick ? onClick : () => null}
            onError={onError ? onError : () => null}
           />
        
    )
}