import React, { useEffect, useRef, useState } from 'react';
import Header from '../baseComponents/Header';
import Footer from '../baseComponents/Footer';
import { Container } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { Image } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const HealthFair1 = () => {
  const navigate = useNavigate();

  const basePath = './images/pictures/2019-day1/';
  const imageCount = 218;

  const getImageUrl = (imageId) => {
    return `${basePath}${imageId}.jpg`;
  };

  const [modalImage, setModalImage] = useState(null);

  const openModal = (imageId) => {
    setModalImage(imageId);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  const rowRefs = useRef([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleScroll = () => {
      rowRefs.current.forEach((row, rowIndex) => {
        const rowTop = row.offsetTop;
        const rowHeight = row.offsetHeight;
        const windowHeight = window.innerHeight;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (scrollTop >= rowTop - windowHeight && scrollTop <= rowTop + rowHeight) {
          const imageRefs = row.querySelectorAll('.image-aline');
          imageRefs.forEach((image, index) => {
            setTimeout(() => {
              image.style.animationDelay = `${index * 0.1}s`;
              image.classList.add('fade-in-up');
            }, 1000); 
          });
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Header />

      {/* banner start */}
      <div className="banner dark-overlay dark-overlay-2" style={{ backgroundImage: 'url(images/banner/7.jpg)' }}>
        <div className="container">
          <div className="banner-about">
            <h1>2019 Health Fair Day – 1</h1>
          </div>
        </div>
      </div>
      {/* banner ends */}

      <Container className='slideshow-container'>
        <div>
          <a className="show-text" onClick={() => navigate(`/slideshowHF1`)}>Show Slideshow</a>
        </div>
      </Container>

      <section className="fade-in-up">
        {[...Array(Math.ceil(imageCount / 4)).keys()].map((rowIndex) => (
          <div key={rowIndex} ref={(el) => (rowRefs.current[rowIndex] = el)}>
            {Array.from({ length: 4 }, (_, index) => {
              const imageIndex = rowIndex * 4 + index + 1;
              return (
                <LazyLoadImage
                  key={index}
                  src={getImageUrl(imageIndex)}
                  alt={`Image ${imageIndex}`}
                  className="image-aline"
                  effect="opacity"
                  visibleByDefault={false}
                  placeholder={
                    <Image
                      src='./images/pictures/2019-day1/' 
                      preview={false}
                      alt={`Image ${imageIndex}`}
                      className="image-aline"
                    />
                  }
                />
              );
            })}
          </div>
        ))}
      </section>

      {modalImage && (
        <div className="zoom-image" onClick={closeModal}>
          <img src={getImageUrl(modalImage)} alt={`Image ${modalImage}`} />
        </div>
      )}
      <Footer />
    </>
  );
};

export default HealthFair1;
