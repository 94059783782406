import React from 'react';
import Header from '../baseComponents/Header';
import Footer from '../baseComponents/Footer';
import { Card, Row, Col, Divider, Select } from "antd";
import { Images } from '../baseComponents/Images';

const DisasterRelief = () => {
  return (
    <>
      <Header />
      {/* banner start */}
      <div className="banner dark-overlay dark-overlay-2" style={{ backgroundImage: 'url(images/banner/5.jpg)' }}>
        <div className="container">
          <div className="banner-disaster">
            <h1>Disaster Relief Committee</h1>
          </div>
        </div>
      </div>
      {/* banner ends */}
      <div className='entry-content'>
        <Row>
          <Col lg={8}>
            <Images className={"image"} src="/images/hospitality/disasterRelif.png" />
          </Col>
          <Col lg={15}>
            <div className='title-text'>
              <h1>Disaster Relief Committee</h1>
              {/* <p className='subtitle'>The mission of the Triangle Area Hindu Temples</p> */}
            </div>

            <p className='sub-text'>TAHTS is a registered 501(c)(3) nonprofit organization. The Disaster Relief Committee (DRC), under the auspices of TAHTS, is collecting emergency relief for Indian to combat the devastation caused by Covid-19’s second wave.</p>
            <p className='sub-text'> India’s medical system is crumbling under the immense stress caused by the pandemic. TAHTS DRC is reaching out to the RTP community to offer immediate support to India’s medical community during these pressing times.</p>
            <p className='sub-text'>TAHTS DRC is Sewa International’s recognized collaborator in the RTP area. This is a collective effort that can save lives, defeat hunger, assure distressed people, and help India in its decisive fight against COVID-19. Please join us in this fight by donating generously. Together, we can serve better.</p>

            <h6 className='sub-text'>Every dollar counts! Donate generously!</h6>

            <p className='sub-text'>
              Regards,<br></br>
              TAHTS DRC
            </p>
          </Col>
        </Row>
      </div>
      <Footer />
    </>


  );
}

export default DisasterRelief;
