import React, { useState } from 'react';
import { Logo } from './Logo';
import { Button } from 'antd';
import { Dropdown, Menu } from 'antd';

const items = [
  {
    key: '1',
    label: (
      <a href="/hospitality" className="nav-text">
        Hospitality Committee
      </a>
    ),
  },
  {
    key: '2',
    label: (
      <a href="/disasterrelief" className="nav-text">
        Disaster Relief Committee
      </a>
    ),
  },
];

const Header = () => {
  const [active, setActive] = useState(false);
  const [icon, setIcon] = useState(false);

  const navToggle = () => {
    setActive(!active);
    setIcon(!icon);
  };

  return (
    <nav className={`nav ${active ? 'nav-active' : ''}`}>
      <a href="#" className="logo">
        <Logo src={'/images/logo/tahts.png'} />
      </a>
      <ul className="nav-menu">
        <li className="">
          <a href="/" className="nav-text">
            Home Page
          </a>
        </li>
        <li className="">
          <a href="/hindufaith" className="nav-text">
            Hindu Faith
          </a>
        </li>
        <li className="">
          <a href="/aboutus" className="nav-text">
            About Us
          </a>
        </li>
        <li className="">
          <a href="/community" className="nav-text">
            Community Support Groups
          </a>
        </li>
        <li className="">
          <Dropdown
            overlay={<Menu>{items.map((item) => <Menu.Item key={item.key}>{item.label}</Menu.Item>)}</Menu>}
            placement="bottomLeft"
          >
            <a href="#" className="nav-text">
              Committees
            </a>
          </Dropdown>
        </li>
        <li className="">
          <a href="/contactus" className="nav-text">
            Contact Us
          </a>
        </li>
        <li className="">
        <a className="btn-custom" href="https://www.paypal.com/donate/?hosted_button_id=VYXXT3SGGMUNE" style={{ color: "white", height:"10px" }}>
  Donate Here
</a>

        </li>
      </ul>
      <div onClick={navToggle} className={`nav-toggler ${icon ? 'toggle' : ''}`}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
};

export default Header;
