import './App.scss';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pageComponents/Home';
import AboutUs from './pageComponents/AboutUs';
import Community from './pageComponents/Community';
import HinduFaith from './pageComponents/HinduFaith';
import ContactUs from './pageComponents/ContactUs';
import Hospitality from './pageComponents/Hospitality';
import DisasterRelief from './pageComponents/DisasterRelief';
import Donation from './pageComponents/Donation';
import HealthFair2 from './pageComponents/HealthFair2';
import HealthFair1 from './pageComponents/HealthFair1';
import HealthFair2023 from './pageComponents/HealthFair2023';
import NewLetterPage from './pageComponents/NewLetterpage';
import Slideshow from './baseComponents/Slideshow';
import SlideshowHF1 from './pageComponents/SlideshowHF1';
import SlideshowHF2 from './pageComponents/SlideshowHF2';
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>

        <Routes>
          <Route path='/hindufaith' element={<HinduFaith/>}/>
        </Routes>

        <Routes>
          <Route path='/aboutus' element={<AboutUs />}/>
        </Routes>

        <Routes>
        <Route path='/community' element={<Community/>}/>
        </Routes>

        <Routes>
          <Route path='/hospitality' element={<Hospitality/>}/>
        </Routes>

        <Routes>
          <Route path='/disasterrelief' element={<DisasterRelief/>}/>
        </Routes>

        <Routes>
          <Route path='/contactus' element={<ContactUs/>}/>
        </Routes>

        <Routes>
          <Route path='/donation' element={<Donation/>}/>
        </Routes>

        <Routes>
          <Route path='/healthifair2023' element={<HealthFair2023/>}/>
        </Routes>

        <Routes>
          <Route path='/slideshow' element={<Slideshow/>}/>
        </Routes>

        
        <Routes>
          <Route path='/slideshowHF1' element={<SlideshowHF1/>}/>
        </Routes>

        <Routes>
          <Route path='/slideshowHF2' element={<SlideshowHF2/>}/>
        </Routes>

        <Routes>
          <Route path='/healthifair1' element={<HealthFair1/>}/>
        </Routes>

        <Routes>
          <Route path='/healthifair2' element={<HealthFair2/>}/>
        </Routes>

        <Routes>
          <Route path='/newletterpage' element={<NewLetterPage/>}/>
        </Routes>

      </Router>
    </div>
  );
}

export default App;
