import React from 'react';
import Header from '../baseComponents/Header';
import Footer from '../baseComponents/Footer';
import Slideshow from '../baseComponents/Slideshow';
import { Container } from 'reactstrap';


const SlideshowHF1 = () => {
  return (
    <>
      <Header />

      {/* banner start */}
      <div className="banner dark-overlay dark-overlay-2" style={{ backgroundImage: 'url(images/banner/7.jpg)' }}>
        <div className="container">
          <div className="banner-about">
            <h1>2019 Health Fair Day-1 Slideshow</h1>
          </div>
        </div>
      </div>
      {/* banner ends */}

      <Container>
      <Slideshow imageCount={200} imagePath="./images/pictures/2019-day1" interval={2000} />
      </Container>

      <Footer />
    </>
  );
};

export default SlideshowHF1;
